import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AiAuthTokenContextService } from '@x/ai/client';
import { InjectLogger, Logger } from '@x/common/log';
import { Operation, OperationObserverService } from '@x/common/operation';
import { LocalStorage } from '@x/common/storage';
import { MenuService } from '@x/dashboard/menu';
import {
  AuthService,
  EcommerceAuthTokenContextService,
  ILoginObject,
  IUserDetailObject,
  UserService,
} from '@x/ecommerce/domain-client';
import { SageAuthTokenContextService } from '@x/sage/client';
import { CredentialsInput, LoginObject } from '@x/schemas/ecommerce';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthContextService {
  @InjectLogger()
  log: Logger;

  private readonly authenticatedUserStorageKey = 'auth_user';

  private authenticatedObject$ = new BehaviorSubject<LoginObject | null>(null);
  private user$ = new BehaviorSubject<Operation<IUserDetailObject>>(Operation.create());

  get currentUser(): IUserDetailObject | null {
    return this.user$.getValue().data ?? null;
  }

  get currentUserId(): number | null {
    return this.authenticatedObject$.getValue()?.userId ?? null;
  }

  get token(): string | null {
    return this.authenticatedObject$.value?.token ?? null;
  }

  get isAuthenticated(): boolean {
    return this.token !== null;
  }

  constructor(
    private router: Router,
    private authService: AuthService,
    private localStorage: LocalStorage,
    private authTokenContext: EcommerceAuthTokenContextService,
    private sageAuthTokenContext: SageAuthTokenContextService,
    private aiAuthTokenContext: AiAuthTokenContextService,
    private userService: UserService,
    private menuService: MenuService,
    private operations: OperationObserverService,
  ) {
    const fromStorage = this.localStorage.getItem(this.authenticatedUserStorageKey);
    if (fromStorage) {
      this.setAuthentication(fromStorage);
    }

    this.permissions().subscribe((permissions) => {
      if (permissions) {
        this.menuService.setPermissions(permissions);
      } else {
        this.menuService.clearPermissions();
      }
    });
  }

  user(): Observable<IUserDetailObject | null> {
    return this.user$.pipe(map((s) => s.data ?? null));
  }

  userId(): Observable<number | null> {
    return this.authenticatedObject$.pipe(map((login) => login?.userId ?? null));
  }

  userState(): Observable<Operation<IUserDetailObject>> {
    return this.user$.pipe(delay(2000));
  }

  loadUser() {
    const auth = this.authenticatedObject$.getValue();

    if (!auth) {
      this.log.warn('Attempting to load user with no authentication.');
      return;
    }

    this.operations
      .observe(this.authService.currentUser(), { label: 'Load Current User' })
      .subscribe((state) => {
        this.user$.next(state);
      });
  }

  permissions(): Observable<string[] | null> {
    return this.user().pipe(
      map((user) => {
        if (!user) return null;
        return Array.from(new Set(user.roles.flatMap((role) => role.permissions)));
      }),
    );
  }

  userHasPermissions(permissions: string[]): Observable<boolean> {
    return this.user().pipe(
      map((user) => {
        // first check a few obvious things
        if (!this.isAuthenticated) return false;
        const roles = user?.roles;
        if (!roles) return false;

        // at least one of the user's roles should include each of the permissions
        for (let permission of permissions) {
          if (roles.find((role) => role.permissions.includes(permission)) === undefined) {
            // you shall not pass
            return false;
          }
        }

        // user has permission
        return true;
      }),
    );
  }

  currentUserHasPermissions(permissions: string[]): boolean {
    if (!this.isAuthenticated) return false;

    const roles = this.currentUser?.roles;
    if (!roles) return false;

    // at least one of the user's roles should include each of the permissions
    for (let permission of permissions) {
      if (roles.find((role) => role.permissions.includes(permission)) === undefined) {
        // you shall not pass
        return false;
      }
    }

    // user has permission
    return true;
  }

  login(credentials: CredentialsInput): Observable<ILoginObject> {
    this.clearAuthentication();
    return this.authService.loginUser(credentials).pipe(
      tap((login) => {
        this.log.debug('Login success', login);
        this.setAuthentication(login);
      }),
    );
  }

  logout() {
    this.clearAuthentication();
    this.router.navigate(['/auth/login']);
  }

  private setAuthentication(login: LoginObject) {
    this.authenticatedObject$.next(login);
    this.localStorage.setItem(this.authenticatedUserStorageKey, login);
    this.authTokenContext.setToken(login.token);
    this.sageAuthTokenContext.setToken(login.token);
    this.aiAuthTokenContext.setToken(login.token);
    this.loadUser();
  }

  private clearAuthentication() {
    this.authenticatedObject$.next(null);
    this.localStorage.removeItem(this.authenticatedUserStorageKey);
    this.authTokenContext.clearToken();
    this.sageAuthTokenContext.clearToken();
    this.aiAuthTokenContext.clearToken();
    this.user$.next(Operation.create());
  }
}
